import React from 'react';
import './Result.css'

const Result = (props) => {

    const { error, city, temp, sunrise, sunset, pressure, wind } = props.weather

    let content = null;

    if (!error && city) {
        const sunriseTime = new Date(sunrise * 1000).toLocaleDateString()
        const sunsetTime = new Date(sunset * 1000).toLocaleDateString()
        const time = new Date().toLocaleString()
        content = (
            <div>
                <h4>Wyniki wyszukiwania dla <em>{city}</em></h4>
                <h5>Aktualna data i godzina: {time}</h5>
                <h5>Aktualna temperatura {temp}&deg;C</h5>
                <h5>Wschód o: {sunriseTime}</h5>
                <h5>Zachód o: {sunsetTime}</h5>
                <h5>Siła wiatru: {wind} km/h</h5>
                <h5>Ciśnienie: {pressure} HP/a</h5>
            </div>
        )
    }
    return (
        <div className='result'>
            {error ? `Nie mamy takiego chujowego miasta jak ${city}` : content}
        </div>
    );
}

export default Result;
