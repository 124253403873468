import React from 'react';

const Form = props => {
    return (
        /*<form onSubmit={props.submit}>
        <button type='submit'>Wyślij</button>*/
        <form>
            <input type='text' value={props.value} onChange={props.change}placeholder="Wpisz swoje miasto" />
            
        </form>
    )
}

export default Form;   