import React, { Component } from 'react';
import Form from './Form'
import Result from './Result'
import './App.css';
const APIKey = 'ea942e815ce96d501d0aeed2bf7e1a66'

class App extends Component {

  state = {
    value: '',
    date: '',
    city: '',
    sunrise: '',
    sunset: '',
    temp: '',
    pressure: '',
    wind: '',
    error: false,

  }



  handleInputChange = (event) => {
    this.setState({
      value: event.target.value
    })
  }

  /* stara wersja
   handleCitySubmit = (event) => {
     event.preventDefault()
     console.log('Potwierdzony formularz')
     const API = `//api.openweathermap.org/data/2.5/weather?q=${this.state.value}&appid=${APIKey}&units=metric`;
 
 
     fetch(API)
     .then(response => {
       if(response.ok){
         return response 
       }
         throw Error('Nie udało się')
       })
       .then(response => response.json())
       .then(data => {
         const time = new Date().toLocaleString()
         this.setState({
           error: false,
           date: time,
           city: this.state.value,
           sunrise: data.sys.sunrise,
           sunset: data.sys.sunset,
           temp: data.main.temp,
           pressure: data.main.pressure,
           wind: data.wind.speed,
 
         })
       })
       .catch(error => {
         console.log(error);
         this.setState({
           error: true,
           city: this.state.value
         })
       
       })
 
   }
 */
  /* nowa wersja */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.value.length === 0) return
    if (this.state.value.length === 1) return
    if (this.state.value.length === 2) return
    if (this.state.value.length === 4) return
    if (prevState.value !== this.state.value) {
      const API = `//api.openweathermap.org/data/2.5/weather?q=${this.state.value}&appid=${APIKey}&units=metric`;
      fetch(API)
        .then(response => {
          if (response.ok) {
            return response
          }
          throw Error('Nie udało się')
        })
        .then(response => response.json())
        .then(data => {
          //const time = new Date().toLocaleString()
          this.setState({
            error: false,
            //date: time,
            city: this.state.value,
            sunrise: data.sys.sunrise,
            sunset: data.sys.sunset,
            temp: data.main.temp,
            pressure: data.main.pressure,
            wind: data.wind.speed,

          })
        })
        .catch(error => {
          console.log(error);
          this.setState({
            error: true,
            city: this.state.value
          })

        })
    }
  }

  render() {
    return (
      <div className="App">
        Aplikacja pogodowa
        <Form value={this.state.value} change={this.handleInputChange} submit={this.handleCitySubmit} />
        <Result weather={this.state} />
      </div>
    );
  }
}

export default App
